<template>
  <div>
    <div class="Zhanpingbanner" @click="fabubtn">
      <img class="Zhanpingbannerpc"
        :src="img"
        alt=""
      />
       <img class="Zhanpingbannerios" :src="imgios" alt="" />
    </div>
    <div class="chengguo">
      <div class="chengguotype">
        <div class="chengguotype_l">
          <ul class="chengguotype_big">
            <li>专业分类</li>
            <li @click="type_allclick">全部</li>
            <li @click="type_hjclick">环境设计</li>
            <li @click="type_cpclick">产品设计</li>
            <li @click="type_sjclick">视觉传达设计</li>
          </ul>
          <ul class="chengguotype_small">
            <li>专业细分</li>
            <li
              v-for="(item, index) in type_tongyong"
              :key="index"
              @click="typesmall_change(item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="chengguotype_r">
          <el-select v-model="orderBy" placeholder="请选择排序类型">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.value"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <ul class="chengguo_btmul">
        <li
          class="chengguo_btmulli"
          v-for="item in chengguo_btmul"
          :key="item.id"
          :class="item.titlepic ? '' : 'Zhanping_bisheullizero'"
          @click="chengguo_c(item.id)"
        >
          <div class="chengguo_btmulli_img">
            <img v-lazy="item.titlepic" alt="" />
          </div>
          <div class="chengguo_btmulli_title">{{ item.title }}</div>
          <p class="chengguo_btmulli_type">
            <span>{{ item.activity_one }}</span> -
            <span>{{ item.activity_two }}</span>
          </p>
          <p class="chengguo_btmulli_guankan">
            <icon-svg icon-class="iconlook" /><span>{{
              item.onclick == null ? "0" : item.onclick
            }}</span>
            <icon-svg icon-class="iconmessages" /><span>{{
              item.plnum == null ? "0" : item.plnum
            }}</span>
            <icon-svg icon-class="icongood" /><span>{{
              item.give_like == null ? "0" : item.give_like
            }}</span>
          </p>
          <div class="chengguo_btmulli_user">
            <div class="chengguo_btmulli_username">
              <img v-lazy="item.userpic" alt="" />
              <span>{{ item.username }}</span>
            </div>
            <div class="chengguo_btmulli_usertime">{{ item.create_time }}</div>
          </div>
        </li>
        <li class="lizero" v-if="lizero">
          <img
            src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
            alt=""
          />
        </li>
      </ul>
      <el-pagination
      :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".chengguo .chengguotype_big li", function () {
  $(this).css("color", " #4FC6FF");
  $(this).siblings().css("color", "#666");
  $(".chengguo .chengguotype_small li").eq(1).css("color", " #4FC6FF");
  $(".chengguo .chengguotype_small li").eq(1).siblings().css("color", "#666");
});
$(document).on("click", ".chengguo .chengguotype_small li", function () {
  $(this).css("color", " #4FC6FF");
  $(this).siblings().css("color", "#666");
});
$(document).on("mouseenter", ".chengguo_btmul li", function () {
  $(this).find(".chengguo_btmulli_img img").css({
    "animation": "chengguo_btmulli_imgc 0.2s",
    "animation-fill-mode": "forwards",
  })
});
$(document).on("mouseleave", ".chengguo_btmul li", function () {
  $(this).find(".chengguo_btmulli_img img").css({
    "animation": "chengguo_btmulli_imgr 0.2s",
    "animation-fill-mode": "forwards",
  })
});
export default {
  components: {},
  data() {
    return {
      orderBy: "",
      lizero: false,
      total: 0,
      img: "https://task.hozoin.cn/Works/20210415/sj-6950478220.png",
      imgios: "https://task.hozoin.cn/Works/20210310/sj-6987390500.png",
      userinfo: {},
      options: [
        {
          value: "按发布时间",
          val: "1",
        },
        {
          value: "按热度最高",
          val: "2",
        },
      ],
      chengguo_btmul: [],
      pagesize: 12,
      type_hj: [
        { name: "全部" },
        { name: "规划设计" },
        { name: "景观设计" },
        { name: "建筑设计" },
        { name: "室内设计" },
        { name: "软装设计" },
      ],
      type_cp: [
        { name: "全部" },
        { name: "服装设计" },
        { name: "家具设计" },
        { name: "交通工具设计" },
        { name: "轻工产品设计" },
        { name: "机械装备设计" },
      ],
      type_sj: [
        { name: "全部" },
        { name: "广告设计" },
        { name: "包装设计" },
        { name: "图书设计" },
        { name: "展陈设计" },
        { name: "品牌标志设计" },
      ],
      type_all: [{ name: "全部" }],
      type_tongyong: [{ name: "全部" }],
      typebig: "",
      typesmall: "",
    };
  },
  methods: {
    currentchange(index) {
      document.documentElement.scrollTop = 0;
      this.typeaxios(index);
    },
    typeaxios(index) {
      var that = this;
      that.chengguo_btmul = [];
      that.lizero = true;
      this.$axios({
        url: "/api/getReviewPhotoList",
        method: "post",
        data: {
          activity_one: that.typebig,
          activity_two: that.typesmall,
          orderBy: that.orderBy,
          page: index,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero = false;
          that.total = res.data.result.count;
          that.chengguo_btmul = res.data.result.list;
          var count = res.data.result.list.length % 4;
          var li = "";
          if (count == 2) {
            that.chengguo_btmul.push(li, li);
          } else if (count == 3) {
            that.chengguo_btmul.push(li);
          }
        } else {
          that.lizero = true;
        }
      });
    },
    type_allclick() {
      var that = this;
      that.type_tongyong = that.type_all;
      that.typebig = "";
      that.typesmall = "";
      that.typeaxios();
    },
    type_hjclick() {
      var that = this;
      that.type_tongyong = that.type_hj;
      that.typebig = "环境设计";
      that.typesmall = "";
      that.typeaxios();
    },
    type_cpclick() {
      var that = this;
      that.type_tongyong = that.type_cp;
      that.typebig = "产品设计";
      that.typesmall = "";
      that.typeaxios();
    },
    type_sjclick() {
      var that = this;
      that.type_tongyong = that.type_sj;
      that.typebig = "视觉传达设计";
      that.typesmall = "";
      that.typeaxios();
    },
    typesmall_change(type) {
      var that = this;
      if (type == "全部") {
        that.typesmall = "";
      } else {
        that.typesmall = type;
      };
      that.typeaxios();
    },
    chengguo_c(id) {
      this.$router.push({ path: "/Chengguo_c", query: { data: id } });
    },
    fabubtn() {
      if (this.userinfo != null) {
        this.$router.push({ path: "/Sjfabu" });
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
  },
  created() {
    this.fenxiang(
          "和作营-成果转化",
          "售卖原创作品，完成设计创意成果转化，丰富个人作品利于找工作。",
           "https://img.hozoin.cn/news_img/20210419/4cc727439f2768e1eee636f2ca1ed20e.jpeg"
        );
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.typeaxios(1);
  },
  watch: {
    orderBy() {
      this.typeaxios(1);
    },
  },
};
</script>
<style>
@import "../assets/css/chengguo.css";
</style>
